<template>
  <div>
    <b-modal
      ref="modal"
      class="modal-confirm"
      content-class="rounded-10 position-relative overflow-hidden border-0"
      size="xl"
      hide-footer
      hide-header
      no-close-on-backdrop
      :visible="show"
      centered
    >
      <div
        class="header text-18 d-flex align-items-center justify-content-between font-weight-bold"
      >
        {{ title }}
        <i
          class="icon-X text-primary d-block text-32 cursor-pointer"
          @click="$emit('close')"
        ></i>
      </div>
      <div class="content d-flex flex-column align-items-center">
        <b-img
          width="180"
          height="180"
          :srcset="img || handclapImg"
          class="m-0"
          alt=""
        ></b-img>
        <div class="font-weight-bold text-18 text-primary text-center mt-2">
          {{ content }}
        </div>
      </div>
      <div class="footer py-3 d-flex align-items-center justify-content-center">
        <b-button
          v-if="hasCountdonwBtn"
          variant="primary"
          size="sm"
          @click="$emit('close')"
          >{{ $t('自動跳轉登入頁面') }}({{ debounceSec }})</b-button
        >
        <b-button v-else variant="primary" size="sm" @click="$emit('close')">{{
          $t('確定')
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import handclapImg from '@/assets/img/modal/handclap.png';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
    hasCountdonwBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      handclapImg,
      debounceSec: 5,
    };
  },
  mounted() {
    if (this.hasCountdonwBtn) {
      this.debounceSec = 5;
      this.timer = setInterval(() => {
        this.debounceSec -= 1;
        if (this.debounceSec === 0) {
          clearInterval(this.timer);
          this.goLogin();
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    goLogin() {
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal-dialog {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  color: $font-grayscale-1;
  .modal-body {
    padding: 0;
  }
}
.header {
  padding: 14px 8px 14px 16px;
  border-bottom: 1px solid $line-1;
}
.content {
  padding: 8px 50px 20px;
  background: $bgsection;
}
.btn-wrap {
  left: 20px;
  bottom: 32px;
}
</style>
