<template>
  <div
    class="forget-password overflow-auto w-100 h-100 d-flex flex-column align-items-center"
  >
    <base-header
      :title="$t('忘記密碼')"
      :has-logo="!$device.isMobile"
      :is-title-bold="true"
      :left-arrow="$device.isMobile"
      :back-on-click-left="$device.isMobile"
      :right-text="$device.isMobile ? $t('送出') : ''"
      @on-click-right="onSubmit(activeTab)"
    ></base-header>
    <div v-if="$device.isMobile" class="w-100 h-100 p-3 mobile-wrapper">
      <b-tabs
        nav-class="login-register-tabs"
        content-class="mt-3"
        class="w-100"
        justified
        @activate-tab="initialData($event)"
      >
        <b-tab
          :title="$t('電子信箱')"
          title-link-class="login-register-tab"
          active
        >
          <b-form @submit.prevent="onSubmit('email')">
            <b-form-group label-for="email" class="phoneNum-input">
              <b-form-input
                id="email"
                ref="email"
                v-model="$v.formData.email.$model"
                name="email"
                aria-describedby="input-email-feedback"
                type="email"
                :placeholder="$t('請輸入電子信箱')"
                :state="formData.isPhoneNumValid"
                @input="phoneNumInputHandler"
              ></b-form-input>
              <b-form-invalid-feedback
                id="input-phoneNum-feedback"
                :state="formData.isPhoneNumValid"
                class="mt-0"
              >
                <div v-if="$v.formData.email.$invalid" class="error">
                  *{{ $t('請輸入電子信箱') }}
                </div>
                <div
                  v-else-if="backendError === 'User not exist!'"
                  class="error"
                >
                  *{{ $t('電子信箱尚未註冊') }}
                </div>
                <div
                  v-else-if="
                    backendError === 'Cannot send message in limit time'
                  "
                  class="error"
                >
                  {{ $t('*操作過於頻繁，請稍後再嘗試') }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="text-left mt-3 email-notice">
              {{ $t('按下送出後請至信箱收信，點擊信中連結即可重設密碼。') }}
            </div>
          </b-form>
        </b-tab>
        <b-tab :title="$t('手機號碼')" title-link-class="login-register-tab">
          <b-form @submit.prevent="onSubmit('phone')">
            <b-form-group label-for="phoneNum" class="phoneNum-input mr-1">
              <b-form-input
                v-if="isCN"
                id="phoneNum"
                ref="phoneNum"
                v-model="$v.formData.phoneNum.$model"
                name="phoneNum"
                aria-describedby="input-phoneNum-feedback"
                type="tel"
                :placeholder="$t('請輸入手機號碼')"
                :state="formData.isPhoneNumValid"
                @input="phoneNumInputHandler"
              ></b-form-input>
              <b-input-group v-else>
                <b-button
                  class="region d-flex align-items-center justify-content-between pl-3 text-center"
                  size="md"
                  @click="isRegionEditing = true"
                >
                  <span>{{ formData.currentCallingCode }}</span>
                  <i class="icon-Chevron-down"></i>
                </b-button>
                <b-form-input
                  id="phoneNum"
                  ref="phoneNum"
                  v-model="$v.formData.phoneNum.$model"
                  name="phoneNum"
                  aria-describedby="input-phoneNum-feedback"
                  type="tel"
                  :placeholder="$t('請輸入手機號碼')"
                  :state="formData.isPhoneNumValid"
                  @input="phoneNumInputHandler"
                ></b-form-input>
              </b-input-group>
              <b-form-invalid-feedback
                id="input-phoneNum-feedback"
                :state="formData.isPhoneNumValid"
                class="mt-0"
              >
                <div v-if="!$v.formData.phoneNum.required" class="error">
                  {{ $t('*請輸入手機號碼') }}
                </div>
                <div v-else-if="!$v.formData.phoneNum.numeric" class="error">
                  {{ $t('*手機號碼必須為數字') }}
                </div>
                <div v-else-if="!$v.formData.phoneNum.isValid" class="error">
                  {{ $t('*請輸入正確的手機號碼') }}
                </div>
                <div
                  v-else-if="backendError === 'User not exist!'"
                  class="error"
                >
                  {{ $t('*手機號碼尚未註冊') }}
                </div>
                <div
                  v-else-if="
                    backendError === 'Cannot send message in limit time'
                  "
                  class="error"
                >
                  {{ $t('*操作過於頻繁，請稍後再嘗試') }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>

            <div class="w-100 d-flex flex-row">
              <b-form-group
                label-for="verificationCode"
                class="verificationCode-input w-100 mr-1"
              >
                <b-form-input
                  id="verificationCode"
                  ref="verificationCode"
                  v-model="$v.formData.verificationCode.$model"
                  name="verificationCode"
                  aria-describedby="input-verificationCode-feedback"
                  type="text"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="one-time-code"
                  :placeholder="$t('輸入驗證碼')"
                  :state="formData.isVerificationCodeValid"
                  @input="verificationCodeInputHandler"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-verificationCode-feedback"
                  :state="formData.isVerificationCodeValid"
                  class="mt-0"
                >
                  <div
                    v-if="!$v.formData.verificationCode.required"
                    class="error"
                  >
                    {{ $t('*請輸入驗證碼') }}
                  </div>
                  <div
                    v-else-if="
                      backendError === 'Code not correct' ||
                      backendError === 'body Missing verificationId'
                    "
                    class="error"
                  >
                    {{ $t('*驗證碼錯誤') }}
                  </div>
                  <div
                    v-else-if="backendError === 'Message is overdue'"
                    class="error"
                  >
                    {{ $t('*驗證碼已逾期') }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-button
                class="send-verification-button w-100 ml-1"
                :class="{countdown: isCooldown, disabled: isCooldown}"
                size="md"
                @click="sendResetPasswordOtp()"
              >
                {{ isCooldown ? `${count}秒...` : btnText }}
              </b-button>
            </div>
          </b-form>
        </b-tab>
      </b-tabs>
    </div>
    <div
      v-else
      class="w-100 h-100 d-flex align-items-center justify-content-center"
    >
      <div
        class="web-wrapper text-center position-relative d-flex flex-column align-items-center"
      >
        <h1 class="pb-3">{{ $t('忘記密碼') }}</h1>
        <b-tabs
          nav-class="login-register-tabs"
          content-class="mt-3"
          class="w-100"
          justified
          @activate-tab="initialData($event)"
        >
          <b-tab
            :title="$t('電子信箱')"
            title-link-class="login-register-tab"
            active
          >
            <b-form @submit.prevent="onSubmit('email')">
              <b-form-group label-for="email" class="phoneNum-input">
                <b-form-input
                  id="email"
                  ref="email"
                  v-model="$v.formData.email.$model"
                  name="email"
                  aria-describedby="input-email-feedback"
                  type="email"
                  :placeholder="$t('請輸入電子信箱')"
                  :state="formData.isPhoneNumValid"
                  @input="phoneNumInputHandler"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-email-feedback"
                  :state="formData.isPhoneNumValid"
                  class="mt-0"
                >
                  <div v-if="$v.formData.email.$invalid" class="error">
                    {{ $t('*請輸入電子信箱') }}
                  </div>
                  <div
                    v-else-if="backendError === 'User not exist!'"
                    class="error"
                  >
                    {{ $t('*電子信箱尚未註冊') }}
                  </div>
                  <div
                    v-else-if="
                      backendError === 'Cannot send message in limit time'
                    "
                    class="error"
                  >
                    {{ $t('*操作過於頻繁，請稍後再嘗試') }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-button
                class="submit-button w-100 p-0"
                variant="primary"
                size="lg"
                @click="onSubmit('email')"
              >
                {{ $t('送出') }}
              </b-button>
              <div class="text-left mt-3 email-notice">
                {{ $t('按下送出後請至信箱收信，點擊信中連結即可重設密碼。') }}
              </div>
            </b-form>
          </b-tab>
          <b-tab :title="$t('手機號碼')" title-link-class="login-register-tab">
            <b-form @submit.prevent="onSubmit('phone')">
              <b-form-group label-for="phoneNum" class="phoneNum-input mr-1">
                <b-form-input
                  v-if="isCN"
                  id="phoneNum"
                  ref="phoneNum"
                  v-model="$v.formData.phoneNum.$model"
                  name="phoneNum"
                  aria-describedby="input-phoneNum-feedback"
                  type="tel"
                  :placeholder="$t('請輸入手機號碼')"
                  :state="formData.isPhoneNumValid"
                  @input="phoneNumInputHandler"
                ></b-form-input>
                <b-input-group v-else>
                  <b-dropdown
                    toggle-class="customDropdown"
                    variant="none"
                    :text="formData.currentCallingCode"
                    no-caret
                    size="md"
                  >
                    <template #button-content>
                      <div class="d-flex align-items-center">
                        {{ formData.currentCallingCode }}
                        <i
                          class="icon-Chevron-down ml-2"
                          style="color: #ff855e"
                        ></i>
                      </div>
                    </template>
                    <b-dropdown-item
                      v-for="region in regionCodes"
                      :key="region.regionCode"
                      @click="changeCode(region)"
                      >{{ region.region }} {{ region.callingCode }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-form-input
                    id="phoneNum"
                    ref="phoneNum"
                    v-model="$v.formData.phoneNum.$model"
                    name="phoneNum"
                    aria-describedby="input-phoneNum-feedback"
                    type="tel"
                    :placeholder="$t('請輸入手機號碼')"
                    :state="formData.isPhoneNumValid"
                    @input="phoneNumInputHandler"
                  ></b-form-input>
                </b-input-group>
                <b-form-invalid-feedback
                  id="input-phoneNum-feedback"
                  :state="formData.isPhoneNumValid"
                  class="mt-0"
                >
                  <div v-if="!$v.formData.phoneNum.required" class="error">
                    {{ $t('*請輸入手機號碼') }}
                  </div>
                  <div v-else-if="!$v.formData.phoneNum.numeric" class="error">
                    {{ $t('*手機號碼必須為數字') }}
                  </div>
                  <div v-else-if="!$v.formData.phoneNum.isValid" class="error">
                    {{ $t('*請輸入正確的手機號碼') }}
                  </div>
                  <div
                    v-else-if="backendError === 'User not exist!'"
                    class="error"
                  >
                    {{ $t('*手機號碼尚未註冊') }}
                  </div>
                  <div
                    v-else-if="
                      backendError === 'Cannot send message in limit time'
                    "
                    class="error"
                  >
                    {{ $t('*操作過於頻繁，請稍後再嘗試') }}
                  </div>
                </b-form-invalid-feedback>
              </b-form-group>

              <div class="d-flex flex-row">
                <b-form-group
                  label-for="verificationCode"
                  class="verificationCode-input"
                >
                  <b-form-input
                    id="verificationCode"
                    ref="verificationCode"
                    v-model="$v.formData.verificationCode.$model"
                    name="verificationCode"
                    aria-describedby="input-verificationCode-feedback"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    autocomplete="one-time-code"
                    :placeholder="$t('輸入驗證碼')"
                    :state="formData.isVerificationCodeValid"
                    @input="verificationCodeInputHandler"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    id="input-verificationCode-feedback"
                    :state="formData.isVerificationCodeValid"
                    class="mt-0"
                  >
                    <div
                      v-if="!$v.formData.verificationCode.required"
                      class="error"
                    >
                      {{ $t('*請輸入驗證碼') }}
                    </div>
                    <div
                      v-else-if="
                        backendError === 'Code not correct' ||
                        backendError === 'body Missing verificationId'
                      "
                      class="error"
                    >
                      {{ $t('*驗證碼錯誤') }}
                    </div>
                    <div
                      v-else-if="backendError === 'Message is overdue'"
                      class="error"
                    >
                      {{ $t('*驗證碼已逾期') }}
                    </div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                  class="send-verification-button ml-3 p-0"
                  variant="primary"
                  :class="{countdown: isCooldown, disabled: isCooldown}"
                  size="md"
                  @click="sendResetPasswordOtp()"
                >
                  {{ isCooldown ? `${count}秒...` : btnText }}
                </b-button>
              </div>
              <b-button
                class="submit-button w-100 p-0"
                variant="primary"
                size="lg"
                @click="onSubmit('phone')"
              >
                {{ $t('送出') }}
              </b-button>
            </b-form>
          </b-tab>
        </b-tabs>
        <div
          class="previos-page position-absolute cursor-pointer d-flex align-items-center"
          @click="$router.push({name: 'login'})"
        >
          <i class="icon-Chevrons-left text-24 mr-1 mt-1"></i>
          <span class="text-16">{{ $t('返回上一頁') }}</span>
        </div>
      </div>
    </div>
    <action-sheet
      v-if="isRegionEditing && $device.isMobile"
      :title="$t('國家/地區')"
      :touch-move-to-close="true"
      sheet-height="158"
      @close="closeEdit"
    >
      <ul class="list-group">
        <li
          v-for="regionCode in regionCodes"
          :key="regionCode.code"
          class="avatar list-group-item-action d-flex align-items-center pl-3"
          @click="sheetEvent(regionCode.callingCode, regionCode.regionCode)"
        >
          <span class="mr-2">{{ regionCode.region }}</span>
          <span>{{ regionCode.callingCode }}</span>
        </li>
      </ul>
    </action-sheet>
    <ModalSignUpResult
      v-if="isModalShow"
      :show="isModalShow"
      :has-countdonw-btn="true"
      :title="$t('請至信箱收信')"
      :content="$t('請至信箱收信以重設密碼')"
      @close="closeModal"
    ></ModalSignUpResult>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import {required, numeric, email} from 'vuelidate/lib/validators';
import {
  isValidPhoneNumber,
  getNationalPhoneNumber,
  getInternationalPhoneNumber,
  getUsernameByInternationalPhoneNumber,
} from '@/lib/validation/phoneNumber';
import delay from '@/lib/base/delay';
import ActionSheet from '@/components/Base/ActionSheet.vue';
import BackEvent from '@/lib/base/backEvent.js';
import {clientVersion} from '@/constant/env';
import ModalSignUpResult from '@/components/Modal/ModalSignUpResult.vue';

export default {
  components: {
    BaseHeader,
    ActionSheet,
    ModalSignUpResult,
  },
  data() {
    return {
      isModalShow: false,
      activeTab: 'email',
      formData: {
        email: '',
        phoneNum: '',
        verificationCode: '',
        verificationId: '',
        isPhoneNumValid: null,
        isVerificationCodeValid: null,
        currentCallingCode: '',
        currentRegionCode: '',
      },
      backendError: '',
      isCooldown: false,
      count: 180,
      btnText: this.$t('傳送驗證碼'),
      clientVersion,
      isRegionEditing: false,
      getNationalPhoneNumber,
      getInternationalPhoneNumber,
      regionCodes: [
        {
          region: this.$t('台灣'),
          callingCode: '+886',
          regionCode: 'TW',
        },
        {
          region: this.$t('香港'),
          callingCode: '+852',
          regionCode: 'HK',
        },
        {
          region: this.$t('馬來西亞'),
          callingCode: '+60',
          regionCode: 'MY',
        },
        {
          region: this.$t('新加坡'),
          callingCode: '+65',
          regionCode: 'SG',
        },
      ],
    };
  },
  validations: {
    formData: {
      phoneNum: {
        required,
        numeric,
        isValid: (value, vm) => {
          if (vm.phoneNum === '') {
            return false;
          } else {
            return isValidPhoneNumber(vm.phoneNum, vm.currentRegionCode);
          }
        },
      },
      verificationCode: {required},
      email: {required, email},
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
    isCN() {
      return this.clientVersion === 'hjjcn';
    },
    internationalPhoneNumber() {
      if (!this.isValidPhoneNumber) {
        return '';
      }

      return getInternationalPhoneNumber(
        this.formData.currentCallingCode + this.formData.phoneNum
      );
    },
    username() {
      if (!this.isValidPhoneNumber) {
        return '';
      }

      return getUsernameByInternationalPhoneNumber(
        this.internationalPhoneNumber
      );
    },
    isValidPhoneNumber() {
      return isValidPhoneNumber(
        this.formData.currentCallingCode + this.formData.phoneNum
      );
    },
  },
  mounted() {
    this.formData.currentRegionCode = this.isCN ? 'CN' : 'TW';
    this.formData.currentCallingCode = this.isCN ? '+86' : '+886';
  },
  methods: {
    initialData(event) {
      this.formData.isPhoneNumValid = null;
      this.formData.isVerificationCodeValid = null;
      this.activeTab = event === 0 ? 'email' : 'phone';
    },
    closeModal() {
      this.isModalShow = false;
      this.$router.push({name: 'login'});
    },
    onSubmit(value) {
      if (this.isLoading) return;
      let invalid = false;
      if (value === 'email') {
        invalid = this.$v.formData.email.$invalid;
      } else {
        invalid =
          this.$v.formData.phoneNum.$invalid ||
          this.$v.formData.verificationCode.$invalid;
      }
      this.formData.isPhoneNumValid = null;
      this.formData.isVerificationCodeValid = null;

      if (invalid) {
        this.$v.$touch();
        this.formData.isPhoneNumValid = this.validatePhoneNum() ? null : false;
        if (value === 'phone') this.formData.isVerificationCodeValid = false;
        this.focusInput();
      } else {
        return value === 'email'
          ? this.sendResetPasswordOtp()
          : this.validateResetPasswordOtp();
      }
    },
    async sendResetPasswordOtp() {
      if (this.activeTab === 'phone') {
        this.$v.$touch();
        this.formData.isPhoneNumValid = null;
      }

      if (!this.validatePhoneNum() && this.activeTab === 'phone') {
        this.formData.isPhoneNumValid = false;
        this.focusInput();
      } else {
        this.$store.commit('env/setIsLoading', true);
        const url = `${window.location.origin}/?email=reset-password`;
        const data = {
          internationalPhoneNumber:
            this.activeTab === 'phone' ? this.internationalPhoneNumber : '',
          email: this.activeTab === 'phone' ? '' : this.formData.email,
          redirect: url,
        };
        try {
          const result = await this.$store.dispatch(
            'user/sendResetPasswordOtp',
            data
          );
          this.$store.commit('env/setIsLoading', false);
          if (this.activeTab === 'email') {
            this.isModalShow = true;
          } else {
            this.formData.verificationId = result.id.id;
            this.isCooldown = true;
            this.countdown();
          }
        } catch (error) {
          this.$store.commit('env/setIsLoading', false);
          this.isCooldown = false;
          this.backendError = error.response.data.message;
          if (this.backendError !== '') {
            this.formData.isPhoneNumValid = false;
          }
        }
      }
    },

    async validateResetPasswordOtp() {
      this.$store.commit('env/setIsLoading', true);
      try {
        await this.$store.dispatch('user/validateResetPasswordOtp', {
          username: this.username,
          verificationId: this.formData.verificationId,
          verificationCode: this.formData.verificationCode,
        });
        this.$store.commit('env/setIsLoading', false);
        return this.$router.push({
          name: 'reset-password',
          query: {
            id: this.formData.verificationId,
            code: this.formData.verificationCode,
            type: 'phone',
          },
        });
      } catch (error) {
        this.$store.commit('env/setIsLoading', false);
        this.backendError = error.response.data.message;
        if (this.backendError !== '') {
          this.formData.isVerificationCodeValid = false;
        }
      }
    },
    async countdown() {
      for (let i = 180; i >= 0; i--) {
        this.count = i;
        await delay(1000);
      }
      this.isCooldown = false;
      this.btnText = this.$t('再一次傳送');
    },
    phoneNumInputHandler() {
      this.backendError = '';
      this.formData.isPhoneNumValid = null;
    },
    verificationCodeInputHandler() {
      this.backendError = '';
      this.formData.isVerificationCodeValid = null;
    },
    validatePhoneNum() {
      const {$error} =
        this.activeTab === 'email'
          ? this.$v.formData.email
          : this.$v.formData.phoneNum;
      return !$error;
    },
    validateVerificationCode() {
      const {$error} = this.$v.formData.verificationCode;
      return !$error;
    },
    focusInput() {
      if (!this.validatePhoneNum()) {
        this.activeTab === 'email'
          ? this.$refs.email.focus()
          : this.$refs.phoneNum.focus();
      } else if (!this.validateVerificationCode()) {
        this.$refs.verificationCode.focus();
      } else {
        return false;
      }
    },
    changeCode(region) {
      this.formData.currentCallingCode = region.callingCode;
      this.formData.currentRegionCode = region.regionCode;
    },
    closeEdit() {
      BackEvent.popEvent();
      this.isRegionEditing = false;
    },
    sheetEvent(callingCode, regionCode) {
      this.formData.isPhoneNumValid = null;
      this.formData.isVerificationCodeValid = null;
      this.formData.currentCallingCode = callingCode;
      this.formData.currentRegionCode = regionCode;
      this.closeEdit();
    },
  },
};
</script>

<style lang="scss" scoped>
.forget-password {
  position: absolute;
  z-index: 2;
  .form-control {
    font-size: 14px;
  }
  .phoneNum-input {
    .region {
      width: 86px;
      font-size: 14px;
      background-color: white;
      color: $font-normal;
      border-right: none;
      border-radius: 4px 0 0 4px;
      border-color: $disable;
      span {
        font-size: 14px;
        height: 20px;
      }
      i {
        font-size: 16px;
        color: $primary;
      }
    }
  }
  .error {
    margin-top: 4px;
    height: 16px;
    color: $negative;
    font-size: 12px;
    text-align: start;
  }
  .send-verification-button {
    height: 38px;
    &.countdown {
      background-color: #ced4da;
      border-color: #ced4da;
    }
  }
}
.mobile-wrapper {
  .email-notice {
    color: #606266;
  }
  /deep/ .login-register-tabs {
    border-bottom: none;
    .nav-item {
      margin: 0;
      &:first-of-type .login-register-tab {
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #00000033;
      }
      &:last-of-type .login-register-tab {
        border-radius: 0;
        border: none;
        border-bottom: 2px solid #00000033;
      }
    }
    .login-register-tab {
      color: #60626660;
      border: none;
      border-radius: 0;
      font-weight: 400;
      height: 38px;
      line-height: 20px;
      background: transparent;
      &.active {
        border: none !important;
        border-bottom: 2px solid $primary !important;
        background: transparent;
        color: $primary;
      }
    }
  }
}
.web-wrapper {
  width: 550px;
  height: 450px;
  background: white;
  padding: 60px 100px 24px 100px;
  border-radius: 10px;
  .phoneNum-input {
    width: 350px;
  }
  .verificationCode-input {
    width: 230px;
  }
  .send-verification-button {
    width: 104px;
  }
  .submit-button {
    height: 48px;
  }
  .previos-page {
    color: $primary;
    bottom: 26px;
  }
  .email-notice {
    color: #606266;
  }
  /deep/ .login-register-tabs {
    border-bottom: none;
    .nav-item {
      margin: 0;
      &:first-of-type .login-register-tab {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid #ced4da;
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        @media screen and (min-width: 768px) {
          border-radius: 0;
          border: none;
          border-bottom: 2px solid #00000033;
        }
      }
      &:last-of-type .login-register-tab {
        border-radius: 0px 4px 4px 0px;
        border-right: 1px solid #ced4da;
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        @media screen and (min-width: 768px) {
          border-radius: 0;
          border: none;
          border-bottom: 2px solid #00000033;
        }
      }
    }
    .login-register-tab {
      color: #60626660;
      border: none;
      border-radius: 0;
      font-weight: 400;
      background: #f8f9fb60;
      height: 38px;
      line-height: 20px;
      @media screen and (min-width: 768px) {
        background: transparent;
      }
      &.active {
        border: 1px solid #ff855e !important;
        color: $primary;
        background: white;
        @media screen and (min-width: 768px) {
          border: none !important;
          border-bottom: 2px solid $primary !important;
          background: transparent;
        }
      }
    }
  }
}
.list-group-item-action {
  height: 51px;
  border-bottom: 1px solid rgba(199, 199, 199, 0.3);
  color: $font-normal;
}
.dropdown {
  width: 90px;
  height: 35px;
  display: flex;
  align-items: center;
  border: 1px solid #ced4da;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  .customDropdown {
    background-color: #fff;
    border: 1px solid #ced4da;
    color: $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dropdown-menu {
    li {
      width: 240px;
    }
  }
}
</style>
